/* heights and widths */

.h-screen {
    height: 80vh;
  }
  
  .w-400 {
    width: 400px;
  }
  
  /* inputs */
  
  input,
  select {
    height: 40px;
    border: 2px solid gray;
    width: 100%;
    padding-left: 20px;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  
  /* card */
  
  .card {
    border: 2px solid gray;
    box-shadow: 0 0 1px rgb(189, 189, 189);
    border-radius: 0 !important;
  }
  
  /* text-sizes */
  
  .text-2xl {
    font-size: 30px !important;
  }
  .text-xl {
    font-size: 25px !important;
  }
  .text-lg {
    font-size: 20px !important;
  }
  .text-md {
    font-size: 18px !important;
  }
  
  .secondary-text {
    color: var(--secondary) !important;
  }
  .primary-text {
    color: var(--primary) !important;
  }
  
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* buttons */
  
  .primary-btn {
    background-color: var(--btn-primary) !important;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: 40px;
  }
  
  .secondary-btn {
    background-color: var(--btn-secondary) !important;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: 40px;
  }
  
  .outlined{
    border: 2px solid var(--primary) !important;
    color: var(--primary) !important;
    background-color: white !important;
  }
  
  .disabled-btn {
    background-color: gray !important;
    color: white;
    border: none !important;
    padding: 10px 20px;
    border-radius: 0 !important;
    cursor: not-allowed;
    height: 40px;
  }
  
  button {
    border-radius: 0 !important;
  }
  
  /* anchor */
  
  a {
    text-decoration: none !important;
    color: var(--primary) !important;
  }
  
  i {
    cursor: pointer;
    font-size: 25px;
  }
  h1,
  p {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .margin-top{
    margin-top: 23px;
  }
  .margin-top-login{
    margin-top: 40px;
    margin-left: 195px;
  }
  /* spinner/loader */
  
  .spinner-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.507);
    z-index: 10000;
  }
  .spinner-border {
    color: white !important;
    height: 80px !important;
    width: 80px !important;
  }
  
  table{
  
    border: 1px solid rgb(185, 185, 185) !important;
  }
  
  .login_form button {
    background: linear-gradient(45deg, #ff0d45, #ff01eb);
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 14px 16px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}
.login_form {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 20px 26px;
  width: 300px;
  /*height: 300px;*/
  background-color: rgba(75, 143, 102, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.18);
}
.login_form input {
  background: transparent;
  color: #0c0c0c;
  border: 1px solid #1677ff;
  border-radius: 6px;
  padding: 14px 16px;
  margin-bottom: 30px;
}
.login_form input:focus {
  outline: none;
  border-color: #fff;
}
.login_form h1 {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 8px;
}

.login_form p {
  margin-top: 0;
  margin-bottom: 26px;
}

